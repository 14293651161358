<template>
  <div class="order">
    <div class="content">
      <div class="body">
      <div class="item">
        <div class="label">订单总吨数/吨（累计）：</div>
        <div class="num">{{data.applyNumber}}</div>
      </div>
      <div class="item">
        <div class="label">订单总吨数/吨（今年）：</div>
        <div class="num">{{data.applyNumberYear}}</div>
      </div>
      <div class="item">
        <div class="label">未发货/吨（今年）：</div>
        <div class="num">{{data.unSendNumberYear}}</div>
      </div>
      <div class="item">
        <div class="label">已发货/吨（今年）：</div>
        <div class="num">{{data.sendNumberYear}}</div>
      </div>
      </div>
    </div>
    <div class="button-container">
      <Button class="button" size="small-plain" @click="$router.push({name: 'OrderIndex'})">查看全部</Button>
      <Button class="button" size="small" @click="$router.push({name: 'OrderAddOrderFirst', params: {seller: 'self'}})">立即下单</Button>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/common/button/CustomButton';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
.order {
  .content {
    width: 100%;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .body {
    padding: 11px 12px 11px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      margin-top: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      word-break: keep-all;
      white-space: nowrap;
      .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #818181;
        //color: #2C2C2C;
      }
      .num {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        color: #2C2C2C;
      }
    }
  }
  
  .button {
    width: 157px;
    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 9px;
    }
  }
}
</style>