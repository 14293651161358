<template>
  <div class="detail-page">
    <Header />
    <Order :data="orderData" />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Order from './order';
import {fetchOrderData} from "@/api/home";

export default {
  components: {
    Header,
    Order,
  },
  data() {
    return {
      orderData: {},
    }
  },
  activated() {
    this.getOrderData();
  },
  methods: {
    async getOrderData() {
      const res = await fetchOrderData();
      this.orderData = res.data;
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;

}
</style>

